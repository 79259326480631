import React from "react";
import { ExplorerWrapper } from "pages/Editor/Explorer/Common/ExplorerWrapper";
import EntityExplorer from "./EntityExplorer";

function ExplorerContent() {
  return (
    <ExplorerWrapper>
      <EntityExplorer />
    </ExplorerWrapper>
  );
}

export default ExplorerContent;
